import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Container, Col, Row } from 'react-bootstrap'
import Img from "gatsby-image"
import './style.scss'

export default props => {
  const data = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "header_bg.jpg" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 2048, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        left: file(relativePath: { eq: "Leeuw_Left.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 700, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        right: file(relativePath: { eq: "Leeuw_Right.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 700, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return (
    <header className="header">
      <div className="background-image" style={{
        backgroundImage: `url(
          ${!!data.file.childImageSharp ? data.file.childImageSharp.fluid.src : data.file}
          )`
      }}>
        <div className="background-color" style={{
          width: '100%',
          height: '100%',
          backgroundColor: props.background,
        }}>
          <Container fluid className="text-center"
          >
            <Row>
              <Col lg={4} className="d-none d-lg-block align-self-center">
                <Img fluid={data.left.childImageSharp.fluid} alt="leeuw links" />
              </Col>
              <Col lg={4}
                style={{
                  paddingBottom: '100px'
                }}
              >
                {props.children}
              </Col>
              <Col lg={4} className="d-none d-lg-block align-self-center">
                <Img fluid={data.right.childImageSharp.fluid} alt="leeuw rechts" />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </header>
  )
}
