import React from 'react'
import HTMLContent from 'gatsby-theme-psg/src/components/Content'
import stars from '../img/stars.png'

export default ({ title, content }) => {
  return (
    <div className="header-content">
      <img src={stars} alt="stars" />
      <h1>{title}</h1>
      <div className="lead">
        <HTMLContent content={content} />
      </div>
    </div>
  )
}
